<script setup>

// import groupBy from 'lodash/groupBy'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'

import OrderKanbanCard from '@/components/Order/OrderKanbanCard.vue'

import draggable from 'vuedraggable'
import { ref, reactive, computed, watch, onMounted, onActivated } from 'vue' 

import { useStore } from 'vuex'
import delay from 'lodash/delay'
const store = useStore()

// onMounted(() => {
//   console.log('onMounted')
//   // updateColumns()
//   // delay(updateColumns, 1000)
   
// })

const orders = computed(() => {
  return store.getters['order/GET_LIST']
})

// const columns = computed(() => {
//   return store.getters['order/getKanban']
// }) 

const updateColumns = () => { 
  console.log('updateColumns()')
  orders.value.forEach(o => {
    // console.log(columns)
    const column = find(columns, { step: o.step })
    // console.log(column)
    if (column) {
      const item = find(column.list, { id: o.id })
      if (!item)
        column.list.push(o)
      // console.log(column)
    }
  })
}

onMounted(() => {
  updateColumns()
})

// watch(orders, (value) => {
//   console.log('orders update')
//   updateColumns()
// })

// const steps = computed(() => {
//   return store.state.order.steps
// }) 

const columns = reactive([
    {
      step: 0,
      title: 'Записался на спектакль',
      list: []
    },
    {
      step: 1,
      title: 'Отправили материалы',
      list: []
    },
    {
      step: 2,
      title: 'Отправлена ссылка на билет',
      list: []
    },
    {
      step: 3,
      title: 'Купил билет',
      list: []
    }
  ]) 

const reorder = (data) => {
  return orderBy(data, 'title', 'asc')
}

const log = function({ column, e }) {
   
  if (e.added) {
    console.log(column.step)
    console.log(`Add step ${column.step} to ${e.added.element.id}`)
    // store.order.dispose('order/')
    store.dispatch('order/updateOrder', {
      id: e.added.element.id,
      step: column.step
    })

    // console.log(column.list)
    
  }

  // if (e.moved) {
  //   column.list = reorder(column.list)
  // }

  console.log(e)
   
}

const updateOrder = function(evt) {
  // store.dispatch('order/updateOrder', {})
  // columns[0].list.push(orders.value[0])
}

</script>
<template>
  <div>
    <!-- <pre>{{ columns }}</pre> -->
  </div>
  <!-- <button @click="updateOrder">Test</button> -->
  <div class="grid grid-cols-4">
    <div v-for="column in columns" :key="column.step"
      class="bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-4">
      <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm mb-3">{{column.title}}</p>
      <draggable :sort="false" :list="column.list" group="order" item-key="id" @change="log({ column, e: $event })">
        <template #item="{element}">
          <div>
            <OrderKanbanCard :item="element" />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>