<script setup>
import { computed, onMounted } from 'vue'
import { DateTime } from "luxon"
import { getFullName  } from '@/utils'
const props = defineProps({
  item: Object
})

const day = computed(() => {
  return DateTime.fromISO(props.item.created_at).setLocale('ru').toLocaleString({ month: 'long', day: 'numeric' })
})

// onMounted(() => {
//   console.log(props.item.created_at)
//   console.log( )
// })

</script>

<template>
  <div class="border p-2 bg-white text-sm mb-2">
    <div>{{ item.title }}</div>
    <div class="text-xs text-gray-400">{{ day }}</div>
    <div>
      <router-link :to="`/contacts/${item.contact.id}`">{{ getFullName(item.contact) }}</router-link>
    </div>
    <div>{{ item.mystery.spectacle.title }}</div>
  </div>
</template>