<script setup>
// import OrderList from '@/components/Order/OrderList.vue'

import Table from '@/components/Table.vue'
import Window from '@/components/Window.vue'
import Content from '@/views/Layouts/Content.vue'

import {
  // BriefcaseIcon,
  // CalendarIcon,
  PlusIcon,
  CogIcon,
  // ChevronDownIcon,
  // CurrencyDollarIcon,
  // LinkIcon,
  // LocationMarkerIcon,
  // PencilIcon,
} from '@heroicons/vue/solid/index.js'

import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import { ref } from 'vue'

import { DateTime } from 'luxon'

// const orders = ref([
//   {
//     id: 1,
//     title: "Заявка 1",
//     orderStep: 0,
//     contact: {
//       name: "Name 1"
//     },
//     createdAt: "01.09.2022",
//     mystery: {
//       name: "Другой"
//     },
//     comment: "Comment 1"
//   },
//   {
//     id: 2,
//     title: "Заявка 2",
//     orderStep: 2,
//     contact: {
//       name: "Name 2"
//     },
//     createdAt: "02.09.2022",
//     mystery: {
//       name: "Другой"
//     },
//     comment: "Comment 2"
//   },
//   {
//     id: 3,
//     title: "Заявка 3",
//     orderStep: 2,
//     contact: {
//       name: "Name 3"
//     },
//     createdAt: "02.09.2022",
//     mystery: {
//       name: "Другой"
//     },
//     comment: "Comment 3"
//   }
// ])

import { computed } from 'vue'
import OrderKanban from '@/components/Order/OrderKanban.vue'

import { useStore } from 'vuex'

import { getFullName } from '@/utils.js'

const store = useStore()

const header = ref({
  id: 'ID',
  title: 'Сделка',
  step: 'Стадия сделки',
  created_at: 'Дата создания',
  contact: 'Контакт', 
  spectacle: 'Спектакль'
})

const defaultTab = computed(() => {
  return store.getters['ui/GET_ORDERS_DEFAULT_TAB']
})

const setOrdersDefaultTab = (val) => {
  // console.log(val)
  store.dispatch('ui/setOrdersDefaultTab', val)
}


const orders = computed(() => {
  return store.getters['order/GET_LIST']
})

const tabs = ref([
  'Канбан',
  'Список'
])

</script>

<template>
  <div>
    <Content>

      <template #header-controls>
        <!-- <div class="flex gap-3 items-center"> -->
        <span class="sm:ml-3">
          <router-link to="/orders/create" class="btn btn-primary">
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Добавить
          </router-link>
        </span>
        <div class="flex-1">
          <input type="text" placeholder="Фильтр" class="w-full" />
        </div>
        <div>
          <button>
            <CogIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <!-- </div> -->
      </template>
      <template #title>
        <div class="">
          <h3 class="text-2xl leading-7 font-medium text-gray-900">Заявки</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Запись на посещение спектаклей</p>
        </div>
      </template>
      <template #content>

        <div>
          <TabGroup :default-index="defaultTab" @change="setOrdersDefaultTab">
            <!-- <TabList>
              <Tab>Канбан</Tab>
              <Tab>Список</Tab>
            </TabList> -->
            <TabList class="space-x-3 mb-3">
              <Tab as="template" v-for="item in tabs" :key="item" v-slot="{ selected }">
                <button
                  :class="[selected ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent', 'flex-1 whitespace-nowrap border-b-2 py-1 px-1 text-sm font-medium outline-none']">{{
                  item }}</button>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OrderKanban />
                <div class="my-5">
                  <img src="/tmp/orders-kanban.png" alt="">
                </div>
              </TabPanel>
              <TabPanel>
                <Window>
                  <Table :items="orders" :header="header">
                    <template #contact="contactProps">

                      <div>
                        {{ getFullName(contactProps.value) }}
                      </div>

                    </template>
                    <template #title="titleProps">
                      <div class="font-semibold">{{ titleProps.value }}</div>
                    </template>
                    <template #created_at="itemProps">
                      <div class="font-semibold">
                        {{ DateTime.fromISO(itemProps.value).setLocale('ru').toLocaleString(DateTime.DATETIME_SHORT) }}
                      </div>
                    </template>
                    <template #step="stepProps">
                      <div v-if="stepProps.value == 0">Записался на спектакль</div>
                      <div v-if="stepProps.value == 1">Отправили материалы</div>
                      <div v-if="stepProps.value == 2">Отправлена ссылка</div>
                      <div v-if="stepProps.value == 3">Купил билет</div>
                    </template>
                    <template #mystery="mysteryProps">
                      <div class="font-semibold">
                        <router-link :to="`/mysteries/${mysteryProps.value.id}`">{{ mysteryProps.value.spectacle.title
                        }}
                        </router-link>
                      </div>
                    </template>
                    <template #controls="controlsProps">
                      <div class="text-sm">
                        <router-link :to="`/orders/${controlsProps.item.id}`">Редактировать</router-link>
                      </div>
                    </template>
                  </Table>
                </Window>
                <!-- <div class="my-5">
                  <img src="/tmp/orders-list.png" alt="">
                </div> -->
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>

      </template>
    </Content>
  </div>
</template>